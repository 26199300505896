import { useState, useEffect } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { auth, db, storage } from "../firebase";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import PropagateLoader from 'react-spinners/esm/PropagateLoader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RegisterProfile = ({ inputs, title }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [progress, setProgress] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const uploadFile = () => {
      if (!file) return;
      const name = new Date().getTime() + file.name;
      const storageRef = ref(storage, `users/${name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          console.log(error);
          toast.error("Error occurred while uploading an image. Please try again.");
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    uploadFile();
  }, [file]);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };

  const handleAdd = async (e) => {
    setLoading(true)
    setError(false);
    e.preventDefault();
    try {
      const res = await createUserWithEmailAndPassword(auth, data.email, data.password);
      let subscription = '';

      // Check the selected user type and set the subscription accordingly
      switch (data.status) {
        case 'distributor':
          subscription = 'notPaid';
          break;
        default:
          subscription = 'non-subscriber';
          break;
      }

      // Create the user document with the appropriate subscription value under 'users' collection
      await setDoc(doc(db, "users", res.user.uid), {
        ...data,
        id: res.user.uid,
        verification: '',
        subscription,
        timeStamp: serverTimestamp(),
        origin: 'users'
      });
      navigate(-1);
      setLoading(false)
      toast.success("User created successfully!");
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(`Error occurred while creating a user. ${err}`);
    }    
  };

  return (
    <div className="flex">
      <div className="flex flex-col w-full">
        <div className="p-4">
          <div className="bg-gray-200 p-4 mb-4">
            <h1 className="text-lg font-semibold text-gray-700">{title}</h1>
          </div>
          <div className="flex">
            <div className="flex-1 text-center">
              <img
                src={
                  file
                    ? URL.createObjectURL(file)
                    : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                }
                alt=""
                className="w-32 h-32 rounded-full object-cover mx-auto"
              />
            </div>
            <div className="flex-1">
              <form onSubmit={handleAdd} className="flex flex-wrap gap-4 justify-center">
                <div className="w-48">
                  <label htmlFor="file" className="flex items-center gap-2 cursor-pointer">
                    Image: <DriveFolderUploadOutlinedIcon className="icon" />
                  </label>
                  <input
                    type="file"
                    id="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    className="hidden"
                  />
                </div>
                {inputs.map((input) => (
                  <div className="w-48" key={input.id}>
                    <label className="flex items-center gap-2">
                      {input.label}
                    </label>
                    <input
                      id={input.id}
                      type={input.type}
                      placeholder={input.placeholder}
                      required
                      onChange={handleInput}
                      className="w-full px-2 py-1 border-b border-gray-300 rounded scrollbar-thin border-0 resize-none shadow-md"
                    />
                  </div>
                ))}
                {/* Dropdown menu for user type */}
                <div className="w-48">
                  <label className="flex items-center gap-2">
                    Status:
                  </label>
                  <select
                    id="status"
                    onChange={handleInput}
                    className="w-full px-2 py-1 border-b border-gray-300 rounded scrollbar-thin border-0 resize-none shadow-md"
                  >
                    <option value="">Select User Type</option>
                    {/* <option value="admin">Admin</option>
                    <option value="support">Support</option>
                    <option value="user">User</option> */}
                    <option value="distributor">Library</option>
                    <option value="producer">Movie Producer</option>
                  </select>
                </div>
                {loading ? (
                  <div className="w-48 bg-zinc-100 py-2 rounded-md">
                    <PropagateLoader color="#3693d6" />
                  </div>
                ) : (
                  <button
                    type="submit"
                    disabled={progress !== null && progress < 100}
                    className="w-48 bg-teal-500 text-white font-bold py-2 rounded-md cursor-pointer disabled:bg-gray-400"
                  >
                    Send
                  </button>
                )}
              </form>
              {progress !== null && (
                <progress value={progress} max="100" className="w-full mt-4"></progress>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterProfile;