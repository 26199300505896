import React from 'react';

const Notifications = () => {
  return (
    <div>
      Notifications
    </div>
  )
};

export default Notifications;
