import React, { useState, useEffect, useContext } from 'react';
import { collection, getDoc, getDocs, updateDoc, doc, increment, arrayRemove, arrayUnion, query, where, orderBy } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { Link } from 'react-router-dom';
import { IoMdHeartEmpty } from "react-icons/io";
import { IoHeartCircle } from "react-icons/io5";
import { DarkModeContext } from '../context/darkModeContext';
import { logo2 } from '../assets';

const Library = () => {
  const { dispatch, darkMode } = useContext(DarkModeContext);
  const userId = auth.currentUser?.uid;
  const [movies, setMovies] = useState([]);
  const [subscribedMovies, setSubscribedMovies] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('movies');
  const [selectedProducerId, setSelectedProducerId] = useState(null); // State for selected producer

  useEffect(() => {
    const fetchLibrary = async () => {
      if (!userId) return;

      try {
        const libraryRef = collection(db, `users/${userId}/library`);
        const librarySnapshot = await getDocs(libraryRef);

        const moviesData = await Promise.all(
          librarySnapshot.docs.map(async (libraryDoc) => {
            const movieId = libraryDoc.id;
            const movieRef = doc(db, 'movies', movieId);
            const movieDoc = await getDoc(movieRef);

            if (movieDoc.exists()) {
              const movieData = movieDoc.data();
              return {
                id: movieId,
                libraryInfo: libraryDoc.data(),
                moviesInfo: movieData,
              };
            } else {
              return null;
            }
          })
        );

        const filteredMoviesData = moviesData.filter((movie) => movie !== null);
        setMovies(filteredMoviesData);
        console.log(filteredMoviesData);
      } catch (error) {
        console.error('Error fetching library:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLibrary();
  }, [userId]);

  useEffect(() => {
    const fetchSubscriptionsAndMovies = async () => {
      if (!userId) return;

      try {
        const subscriptionsRef = collection(db, 'subscriptions');
        const subscriptionsSnapshot = await getDocs(subscriptionsRef);

        const userSubscriptions = subscriptionsSnapshot.docs.filter((subDoc) => subDoc.data().userId === userId);

        const subscriptionsData = await Promise.all(
          userSubscriptions.map(async (subDoc) => {
            const producerId = subDoc.data().producerId;
            const producerRef = doc(db, 'users', producerId);
            const producerDoc = await getDoc(producerRef);

            if (producerDoc.exists()) {
              const producerData = producerDoc.data();
              return {
                id: producerId,
                displayName: producerData.displayName,
                img: producerData.img,
                status: producerData.status,
                country: producerData.country,
                verification: producerData.verification,
              };
            } else {
              return null;
            }
          })
        );

        const filteredSubscriptionsData = subscriptionsData.filter((sub) => sub !== null);
        setSubscriptions(filteredSubscriptionsData);

        // Fetch movies released by subscribed producers
        const subscribedMoviesData = await Promise.all(
          filteredSubscriptionsData.map(async (sub) => {
            const moviesCollection = collection(db, 'movies');
            const moviesQuery = query(
              moviesCollection,
              where('ProducerID', '==', sub.id),
              orderBy('timeStamp', 'desc')
            );
            const moviesSnapshot = await getDocs(moviesQuery);

            return moviesSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
          })
        );

        const allSubscribedMovies = subscribedMoviesData.flat();
        // Sort movies by timestamp (release date) in descending order
        allSubscribedMovies.sort((a, b) => b.timeStamp.seconds - a.timeStamp.seconds);
        setSubscribedMovies(allSubscribedMovies);
        console.log(allSubscribedMovies);
      } catch (error) {
        console.error('Error fetching subscriptions or movies:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionsAndMovies();
  }, [userId]);

  const handleLikePost = async (movieId) => {
    try {
      const movieRef = doc(db, 'movies', movieId);
      const movieSnapshot = await getDoc(movieRef);

      if (movieSnapshot.exists()) {
        const movieData = movieSnapshot.data();
        const userAlreadyLiked = movieData.likedBy && movieData.likedBy.includes(userId);

        if (!userAlreadyLiked) {
          await updateDoc(movieRef, {
            likes: increment(1),
            likedBy: arrayUnion(userId)
          });
          console.log('Movie liked by new user!');
        } else {
          await updateDoc(movieRef, {
            likes: increment(-1),
            likedBy: arrayRemove(userId)
          });
          console.log('Movie unliked by user!');
        }
      }
    } catch (error) {
      console.error('Error updating like:', error);
    }
  };

  const handleProducerClick = (producerId) => {
    setSelectedProducerId((prevProducerId) => (prevProducerId === producerId ? null : producerId));
  };

  const renderMovies = () => (
    <div className="flex w-full h-full flex-wrap gap-4 max-sm:pb-[100px]">
      {movies.length > 0 ? (
        movies.map((movie) => (
          <div key={movie.id} className="flex flex-col rounded-2xl shadow-xl text-white bg-black h-[392px] w-[280px]">
            <div className="relative overflow-hidden rounded-2xl">
              <div className="flex absolute inset-0 h-[15%] bg-gradient-to-b from-black from-2%" />
              <img alt="iTrood" src={movie.moviesInfo.CoverImage} className="w-full h-full object-cover" />
              <div className="absolute inset-0 bg-gradient-to-t from-black from-5%" />
              <div className="flex flex-col justify-between absolute inset-0 p-3 rounded-2xl">
                <div className="flex gap-4 justify-between items-center">
                  <div className="flex gap-2 justify-center items-center">
                    <img
                      src={movie.moviesInfo.ProducerImageUrl}
                      className="rounded-full object-cover h-[30px] w-[30px] bg-zinc-100 hover:bg-zinc-200"
                      alt="Producer"
                    />
                    <div>{movie.moviesInfo.ProducerName}</div>
                  </div>
                  <div>
                    <button onClick={() => handleLikePost(movie.id)} className="like-button">
                      {movie.moviesInfo.likedBy && movie.moviesInfo.likedBy.includes(userId) ? (
                        <IoHeartCircle className="liked-icon" />
                      ) : (
                        <IoMdHeartEmpty className="like-icon" />
                      )}
                    </button>
                  </div>
                </div>
                <div className="mt-4 gap-2">
                  <div>
                    <Link
                      to={`/movies/${movie.moviesInfo.Type === 'Single Film' ? 'film' : 'movie'}/${movie.id}`}
                      className="font-bold text-[18px] hover:underline py-1"
                    >
                      {movie.moviesInfo.MovieTitle}
                    </Link>
                    <div className="text-[10px] py-1">
                      {movie.moviesInfo.Type} |{' '}
                      {Array.isArray(movie.moviesInfo.Genres) ? movie.moviesInfo.Genres.join(', ') : movie.moviesInfo.Genres} |{' '}
                      {movie.libraryInfo.purchaseDate
                        ? new Date(movie.libraryInfo.purchaseDate.seconds * 1000).toLocaleDateString()
                        : 'N/A'}{' '}
                      | {movie.libraryInfo.currency} {movie.libraryInfo.amount}
                    </div>
                  </div>
                  <div className="text-[12px] py-1">{movie.moviesInfo.likes || '0'} Likes</div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="flex h-full w-full justify-center items-center text-center">You haven't purchased any movies yet. The movies you buy will be found here!</p>
      )}
    </div>
  );

  const renderSubscriptions = () => {
    const filteredMovies = selectedProducerId
      ? subscribedMovies.filter((movie) => movie.ProducerID === selectedProducerId)
      : subscribedMovies;

    return (
      <div className="flex w-full h-full flex-wrap gap-4 max-sm:pb-[100px]">
        <div className='flex w-full justify-center items-center gap-[10px]'>
          {subscriptions.length > 0 ? (
            subscriptions.map((producer, index) => (
              <div key={index} onClick={() => handleProducerClick(producer.id)} className="flex flex-col cursor-pointer rounded-full shadow-xl h-[60px] w-[60px] bg-slate-300">
                <img
                  src={producer.img}
                  className={`rounded-full object-cover h-[60px] w-[60px] bg-zinc-100 hover:bg-zinc-200 ${selectedProducerId === producer.id ? 'border-dashed ring-2 ring-blue-500 p-[2px]' : 'unselect'}`}
                  alt={producer.displayName}
                />
              </div>
            ))
          ) : (
            <p className="flex h-full w-full justify-center items-center text-center">You haven't subscribed to any producers yet. Your subscriptions will be found here!</p>
          )}
        </div>
        <div className={`w-full h-full max-sm:min-h-[600px] max-sm:mb-10 rounded-2xl border overflow-x-auto scrollbar-thin ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-zinc-100"}`}>
          <div>
            <div className={`flex w-full h-full flex-row flex-wrap gap-4 overflow-x-auto scrollbar-hide p-2`}>
              {loading ? (
                <SkeletonLoader />
              ) : (
                filteredMovies.length > 0 ? (
                  filteredMovies.map((item, index) => (
                    <Link
                      to={`/movies/${item.Type === 'Single Film' ? 'film' : 'movie'}/${item.id}`}
                      className={`mb-4 cursor-pointer w-[120px] ${index === 0 ? 'pl-15' : ''} ${index === movies.length - 1 ? 'pr-15' : ''}`}
                      key={item.id}
                    >
                      <div className={`h-[180px] w-[120px] shadow-lg rounded-xl hover:shadow-xl mb-4 flex-shrink-0 ${darkMode ? "border" : "light-mode bg-zinc-200"}`}>
                        <img src={item.CoverImage} alt='Movie Cover' className='h-[180px] w-[120px] hover:h-[190px] hover:w-[130px] transition-transform duration-300 transform-gpu hover:scale-110 object-cover rounded-xl shrink-0' />
                      </div>
                      <div>
                        <div className='font-semibold line-clamp-1 hover:text-blue-500'>{item.MovieTitle}</div>
                        <div className='text-[12px] text-slate-400 hover:text-green-400'>{item.Type}</div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <div className='flex flex-col w-full h-full items-center justify-center'>
                    <div className='mt-[100px] max-sm:mt-10 flex-shrink-0'>
                      <img src={logo2} className='w-[250px] shrink-0' />
                    </div>
                    <div className='text-center'>Doesn't have any movie released yet!</div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full w-full">
      <div className='flex w-full gap-4 items-center justify-center font-semibold'>
        <div
          className={`cursor-pointer p-2 ${activeTab === 'movies' ? 'text-[#0085FF]' : 'text-[#8FA3B5]'}`}
          onClick={() => setActiveTab('movies')}
        >
          My Movies
        </div>
        <div
          className={`cursor-pointer p-2 ${activeTab === 'subscriptions' ? 'text-[#0085FF]' : 'text-[#8FA3B5]'}`}
          onClick={() => setActiveTab('subscriptions')}
        >
          Subscriptions
        </div>
      </div>
      {loading ? (
        <div className="flex h-full w-full justify-center items-center text-center">Loading...</div>
      ) : (
        <div>
          {activeTab === 'movies' ? renderMovies() : renderSubscriptions()}
        </div>
      )}
    </div>
  );
};

const SkeletonLoader = () => {
  const { darkMode } = useContext(DarkModeContext);

  const skeletons = Array.from({ length: 10 });

  return (
    <div className='flex flex-row flex-wrap gap-4 overflow-x-auto scrollbar-hide'>
      {skeletons.map((_, index) => (
        <div key={index} className='mb-4 cursor-pointer w-[120px]'>
          <div className={`h-[180px] w-[120px] animate-pulse shadow-lg rounded-xl hover:shadow-xl mb-4 flex-shrink-0 ${darkMode ? "border" : "light-mode bg-zinc-300 animate-pulse"}`}>
          </div>
          <div>
            <div className='font-semibold line-clamp-1 hover:text-blue-500'></div>
            <div className='text-[12px] text-slate-400 hover:text-green-400'></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Library;