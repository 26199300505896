import { TextInput } from 'flowbite-react';
import React, { useContext, useEffect, useState } from 'react';
import { DarkModeContext } from '../context/darkModeContext';
import { getCurrentUserData } from '../auth';
import { RiDeleteBin2Line } from "react-icons/ri";
import { BsMoonStarsFill, BsSunFill } from "react-icons/bs";
import { TbMessageCircle2 } from "react-icons/tb";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { VscFeedback } from "react-icons/vsc";
import { Link } from 'react-router-dom';
import { useUserData } from '../lib/userData';
import PlatformSubscription from '../pages/PlatformSubscription';

const Navbar = () => {
  const { dispatch, darkMode } = useContext(DarkModeContext);
  const userData = useUserData();

  const subscriptionCost = '30000';
  const id = 'iTrood, Inc';

  return (
    <div className="w-full flex justify-between items-center p-5">
      <div className='mb-2 block'>
        {/* <PlatformSubscription cost={subscriptionCost} service={id} /> */}
        {/* <TextInput id="search" type="search" placeholder="Search" style={{ borderRadius: 8 }} /> */}
      </div>
      <div className="items flex items-center">
        {/* <Link to="/recyclebin" className="cursor-pointer flex items-center mr-4">
          <RiDeleteBin2Line size={20} className="icon text-lg mr-1" title='Recycle Bin' />
        </Link> */}
        <div className="cursor-pointer flex items-center mr-4">
          {darkMode ? (
            <BsSunFill
              className="icon text-lg"
              title='Light Mode'
              onClick={() => dispatch({ type: "TOGGLE" })}
            />
          ) : (
            <BsMoonStarsFill
              className="icon text-lg"
              title='Dark Mode'
              onClick={() => dispatch({ type: "TOGGLE" })}
            />
          )}
        </div>
        {/* <Link to="/feedbacks" className="cursor-pointer flex items-center mr-4 relative">
          <VscFeedback size={24} className="icon text-lg" />
          <div className="counter w-4 h-4 bg-blue-500 rounded-full flex items-center justify-center text-white text-xs font-bold absolute top-0 right-0">
            0
          </div>
        </Link> */}
        {/* <Link to="/notifications" className="cursor-pointer flex items-center mr-4 relative">
          <NotificationsNoneOutlinedIcon size={24} className="icon text-lg" />
          <div className="counter w-4 h-4 bg-red-500 rounded-full flex items-center justify-center text-white text-xs font-bold absolute top-0 right-0">
            12
          </div>
        </Link>
        <Link to="/messages" className="cursor-pointer flex items-center mr-4 relative">
          <TbMessageCircle2 size={24} className="text-lg" />
          <div className="counter w-2 h-2 bg-red-500 rounded-full flex items-center justify-center text-white text-xs font-bold absolute top-[2px] right-[1px]">
          </div>
        </Link> */}
        <Link to="/profile" className="cursor-pointer flex items-center">
          <div className="flex items-center ml-4 mr-2">
            {userData?.displayName || "Admin"}
          </div>
          <img
            src={userData?.img}
            alt=""
            className="avatar w-8 h-8 rounded-full object-cover"
          />
        </Link>
      </div>
    </div>
  )
}

export default Navbar;