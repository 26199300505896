import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserSessionPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyDrEq7UvmmcnHCfRt5-iU2HlxsptYe_qNA",
  authDomain: "distribution.itrood.com",
  projectId: "trood-app-c5eae",
  storageBucket: "trood-app-c5eae.appspot.com",
  messagingSenderId: "240565945731",
  appId: "1:240565945731:web:4db33824f5419eb7b362b6",
  measurementId: "G-XJLYMKEJEG"
  ,appName: "iTrood"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
setPersistence(auth, browserSessionPersistence);
const storage = getStorage(app);
const messaging = getMessaging(app);

export { auth, db, storage, messaging };