import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import { IoMdHeartEmpty } from "react-icons/io";
import { IoHeartCircle } from "react-icons/io5";
import { Description } from '@mui/icons-material';

const Posts = () => {
  const [newPosts, setNewPosts] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [liked, setLiked] = useState(false);

  const handleLikePost = () => {
    setLiked(!liked);
  };

  useEffect(() => {
    const fetchNewPosts = async () => {
      try {
        console.log("Fetching new posts...");
        // Calculate the start and end dates for the current week
        const startOfWeek = new Date(currentDate);
        startOfWeek.setHours(0, 0, 0, 0); // Set to the beginning of the day
        const endOfWeek = new Date(currentDate);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        endOfWeek.setHours(23, 59, 59, 999); // Set to the end of the day

        // Convert start and end of the week to Firestore Timestamps
        const startOfWeekTimestamp = Timestamp.fromDate(startOfWeek);
        const endOfWeekTimestamp = Timestamp.fromDate(endOfWeek);

        // Fetch posts made during the current week (starting from Monday)
        const postsCollection = collection(db, 'posts');
        const q = query(
          postsCollection,
          where('timestamp', '>=', startOfWeekTimestamp),
          where('timestamp', '<=', endOfWeekTimestamp)
        );
        const querySnapshot = await getDocs(q);
        const fetchedPosts = [];
        querySnapshot.forEach((doc) => {
          fetchedPosts.push(doc.data());
        });
        console.log("Fetched posts:", fetchedPosts);
        setNewPosts(fetchedPosts);
      } catch (error) {
        console.error('Error fetching new posts:', error);
      }
    };

    fetchNewPosts();
  }, [currentDate]);

  const getCurrentDayName = () => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[currentDate.getDay()];
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className='flex gap-5 justify-between'>
        <h2 className="text-3xl font-semibold mb-4">New Posts</h2>
        <h2 className="text-3xl font-semibold mb-4 flex gap-2">
          <div>Date: {currentDate.toLocaleDateString()}</div>
          <div>|</div>
          <div className={`text-${getCurrentDayName() === 'Sunday' ? 'red' : 'blue'}-500`}>{getCurrentDayName()}</div>
        </h2>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div>
          <div>
            {newPosts.map((post) => (
              <div className='flex gap-2' key={post.id}>
                <Link to={`/movies/movie/${post.season}`}>
                  <img src={post.CoverImage} alt='iTrood Movie Cover' className='h-[180px] w-[120px] hover:h-[190px] hover:w-[130px] transition-transform duration-300 transform-gpu hover:scale-110 object-cover rounded-xl shrink-0' />
                </Link>
                <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-6">
                  <div className="p-4">
                    <h3 className="text-xl font-semibold mb-2">{post.content}</h3>
                    <p className="text-gray-600 mb-2">Posted by: {post.ProducerName}</p>
                    <img src={post.ProducerImageUrl} alt={post.ProducerName} className="w-16 h-16 rounded-full mb-2 float-right" />
                    <p className="text-gray-600 mb-2">Movie: {post.MovieTitle}</p>
                    <p className="text-gray-600 mb-2">Download <Link to={`/movies/movie/${post.season}`} className="text-blue-500 hover:underline">New Episodes</Link></p>
                    <p className="text-gray-600 mt-2">Number of new episodes released this week: {post.episodes?.length}</p>
                    <p className="text-gray-600 mt-2">Episodes Range: {post.episodes[0].title} - {post.episodes[post.episodes?.length - 1].title}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* <div className='flex flex-col rounded-2xl shadow-xl text-white h-[392px] w-[280px]'>
          <div className="relative overflow-hidden rounded-2xl">
            <img
              src='https://m.media-amazon.com/images/M/MV5BMWQ1NzViMTQtMjVhNy00YzA0LTg2MzYtZjUyOWNiNDI2MzhlXkEyXkFqcGdeQXVyMDM2NDM2MQ@@._V1_FMjpg_UX1000_.jpg'
              alt="sectionimage"
              className="w-full h-full object-cover"
            />
            <div className="flex flex-col justify-between absolute inset-0 bg-gradient-to-t from-black from-25% p-3 rounded-2xl">
              <div className='flex gap-4 justify-between items-center'>
                <div className='flex gap-2 justify-center items-center'>
                  <img src='' className='rounded-full object-cover h-[30px] w-[30px] bg-zinc-100 hover:bg-zinc-200' />
                  <div>DJ name</div>
                </div>
                <div>
                  <button onClick={handleLikePost} className="relative">
                    {liked ?
                      <IoHeartCircle className="text-red-500 text-2xl transition transform hover:scale-110" /> :
                      <IoMdHeartEmpty className="text-white text-2xl transition transform hover:scale-110" />
                    }
                    {liked && <div className="absolute top-0 right-0 transform -translate-x-1/2 -translate-y-1/2 text-xs bg-red-500 text-white rounded-full p-1">Liked!</div>}
                  </button>
                </div>
              </div>
              <div className='mt-4 gap-2'>
                <div>
                  <div className='font-bold text-[18px] py-1'>Lift</div>
                  <div className='text-[10px] py-1'>Single Film | 1h 47m | Action, Comedy | 2024</div>
                </div>
                <div className='text-[12px] py-1'>A master thief is wooed by his ex-girlfriend and the FBI to pull off an impossible heist with his international crew on a 777 passenger flight from London to Zurich.</div>
                <div className='text-[12px] py-1'>200 Likes</div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Posts;