import React, { useContext } from 'react';
import { Link, Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from './context/AuthContext';
import Login from './screens/Login';
import Register from './screens/Register';
import { userInputs } from './formSource';

const AuthLayout = () => {
  const { currentUser } = useContext(AuthContext);

  // Check if currentUser exists and if not, navigate to login page
  if (!currentUser) {
    // Show a message to the user indicating that the session has expired
    console.log("Session expired. Please log in again.");
    // You can use a toast or a modal to display this message to the user
  }
  // Redirect to dashboard if user is logged in and session is not expired
  if (currentUser) {
    // You can customize the redirect path according to your app's routes
    return <Navigate to="/" />;
  }

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register inputs={userInputs} title="Register Profile" />} />
      </Routes>
      {/* <div className="text-center text-[14px] text-zinc-400 pt-4">
        <p>Don't have an account yet?{" "}
          <Link to="/register" className="font-bold text-blue-500">
            Sign Up
          </Link>
        </p>
      </div> */}
    </div>
  );
};

export default AuthLayout;
