import React, { useState, useEffect, useRef } from 'react';
import {
  addDoc,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  query,
  where,
  orderBy,
  serverTimestamp,
  getDocs,
  limit,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import { logo2 } from '../assets';
import { useUserData } from '../lib/userData';

const Messages = ({ img }) => {
  const user = auth.currentUser;
  const currentUserId = user ? user.uid : null;

  const [openChatId, setOpenChatId] = useState(null);

  const handleChatOpen = (receiverId) => {
    setOpenChatId(receiverId);
  };

  return (
    <div className='border bg-white h-full flex'>
      <div className='bg-zinc-100 w-[30%] scrollbar-thin'>
        <ChatList handleChatOpen={handleChatOpen} openChatId={openChatId} />
      </div>
      <div className='w-[70%]'>
        {openChatId ? (
          <Message receiverId={openChatId} img={img} />
        ) : (
          <div className='flex flex-col h-full justify-center items-center'>
            <img src={logo2} alt="logo" />
            <div className='text-slate-300 font-semibold'>Open to view chat</div>
          </div>
        )}
      </div>
    </div>
  );
};

const ChatList = ({ handleChatOpen, openChatId }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(db, 'users'),
        where('status', 'in', ['admin', 'support', 'producer', 'distributor'])
      ),
      (snapshot) => {
        const fetchedUsers = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Fetch last message for each user and set users state with last message
        Promise.all(
          fetchedUsers.map(async user => {
            const lastMessage = await fetchLastMessage(user.id);
            return { ...user, lastMessage };
          })
        ).then(usersWithLastMessages => {
          // Sort users based on the timestamp of their last message
          usersWithLastMessages.sort((a, b) => {
            if (!a.lastMessage || !b.lastMessage) return 0; // Handle cases where there is no last message
            return new Date(b.lastMessage.timestamp) - new Date(a.lastMessage.timestamp);
          });
          setUsers(usersWithLastMessages);
        });
      }
    );

    // Subscribe to changes in the messages collection
    const messagesUnsubscribe = onSnapshot(
      collection(db, 'messages'),
      () => {
        // When a change occurs in the messages collection, fetch the latest messages and update the chat list
        const updatedUsers = users.map(async user => {
          const lastMessage = await fetchLastMessage(user.id);
          return { ...user, lastMessage };
        });

        Promise.all(updatedUsers).then(usersWithLastMessages => {
          // Sort users based on the timestamp of their last message
          usersWithLastMessages.sort((a, b) => {
            if (!a.lastMessage || !b.lastMessage) return 0; // Handle cases where there is no last message
            return new Date(b.lastMessage.timestamp) - new Date(a.lastMessage.timestamp);
          });
          setUsers(usersWithLastMessages);
        });
      }
    );

    return () => {
      unsubscribe();
      messagesUnsubscribe();
    };
  }, []);

  const fetchLastMessage = async (receiverId) => {
    try {
      const user = auth.currentUser;
      const currentUserId = user ? user.uid : null;

      if (currentUserId && receiverId) {
        const sortedUserIds = [currentUserId, receiverId].sort();
        const conversationQuery = query(
          collection(db, 'messages'),
          where('userIds', '==', sortedUserIds)
        );

        const conversationSnapshot = await getDocs(conversationQuery);

        if (!conversationSnapshot.empty) {
          const conversationDoc = conversationSnapshot.docs[0].ref;
          const messagesQuery = query(
            collection(conversationDoc, 'chats'),
            orderBy('timestamp', 'desc'), // Order by timestamp in descending order
            limit(1) // Limit to only one document, which is the last message
          );

          const snapshot = await getDocs(messagesQuery);
          if (!snapshot.empty) {
            const lastMessageData = snapshot.docs[0].data();
            return lastMessageData;
          }
        }
      }
      return null; // Return null if no last message found
    } catch (error) {
      console.error('Error fetching last message:', error);
      return null; // Return null in case of an error
    }
  };

  return (
    <div>
      {users.map(user => (
        <ChatRow
          key={user.id}
          receiverId={user.id}
          username={user.displayName}
          img={user.img}
          handleChatOpen={handleChatOpen}
          isSelected={openChatId === user.id}
          lastMessage={user.lastMessage}
        />
      ))}
    </div>
  );
};

const ChatRow = ({ receiverId, username, img, handleChatOpen, isSelected }) => {
  const [lastMessage, setLastMessage] = useState("Say Hi");

  useEffect(() => {
    const fetchLastMessage = async () => {
      try {
        const user = auth.currentUser;
        const currentUserId = user ? user.uid : null;

        if (currentUserId && receiverId) {
          const sortedUserIds = [currentUserId, receiverId].sort();
          const conversationQuery = query(
            collection(db, 'messages'),
            where('userIds', '==', sortedUserIds)
          );

          const conversationSnapshot = await getDocs(conversationQuery);

          if (!conversationSnapshot.empty) {
            const conversationDoc = conversationSnapshot.docs[0].ref;
            const messagesQuery = query(
              collection(conversationDoc, 'chats'),
              orderBy('timestamp', 'desc'), // Order by timestamp in descending order
              limit(1) // Limit to only one document, which is the last message
            );

            const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
              snapshot.forEach((doc) => {
                const lastMessageData = doc.data();
                setLastMessage(lastMessageData); // Set the last message
              });
            });

            return () => unsubscribe();
          } else {
            console.log('Conversation document not found.');
          }
        }
      } catch (error) {
        console.error('Error fetching last message:', error);
      }
    };

    fetchLastMessage();
  }, [receiverId]);

  const handleClick = () => {
    handleChatOpen(receiverId);
  };

  return (
    <div className={`h-[60px] border border-t-slate-200 border-b-slate-200 w-full cursor-pointer p-1 flex items-center flex-shrink-0 gap-4 overflow-hidden ${isSelected ? 'bg-indigo-100' : ''}`} onClick={handleClick}>
      <img src={img} title={username} alt={username} className='h-[40px] w-[40px] rounded-full bg-white shrink-0' />
      <div>
        <div className='text-[15px] font-semibold'>{username}</div>
        <div className='text-[12px] text-gray-400'>
          {lastMessage ? lastMessage.content : "Say Hi"}
        </div>
      </div>
    </div>
  );
};

const Message = ({ receiverId, img }) => {
  const [inputMessage, setInputMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [messageData, setMessageData] = useState(null);
  const messageContainerRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the message container whenever messages change
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const fetchConversation = async () => {
      try {
        const user = auth.currentUser;
        const currentUserId = user ? user.uid : null;

        if (currentUserId && receiverId) {
          const sortedUserIds = [currentUserId, receiverId].sort();
          const conversationQuery = query(
            collection(db, 'messages'),
            where('userIds', '==', sortedUserIds)
          );

          const conversationSnapshot = await getDocs(conversationQuery);

          if (conversationSnapshot.size > 0) {
            const conversationDoc = conversationSnapshot.docs[0].ref;
            const messagesQuery = query(
              collection(conversationDoc, 'chats'),
              orderBy('timestamp', 'asc')
            );

            const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
              const fetchedMessages = snapshot.docs.map(doc => doc.data());
              setMessages(fetchedMessages);
            });

            return () => unsubscribe();
          } else {
            console.log('Conversation document not found.');
          }
        }
      } catch (error) {
        console.error('Error fetching conversation:', error);
      }
    };

    fetchConversation();
  }, [receiverId]);

  const fetchMessageData = async () => {
    try {
      const user = auth.currentUser;
      const currentUserId = user ? user.uid : null;

      if (currentUserId && receiverId) {
        const sortedUserIds = [currentUserId, receiverId].sort();
        const conversationQuery = query(
          collection(db, 'messages'),
          where('userIds', '==', sortedUserIds)
        );

        const conversationSnapshot = await getDocs(conversationQuery);

        if (conversationSnapshot.size > 0) {
          const conversationDoc = conversationSnapshot.docs[0];
          const messageData = conversationDoc.data();
          setMessageData(messageData); // Update state with message data
        } else {
          console.log('Conversation document not found.');
        }
      }
    } catch (error) {
      console.error('Error fetching message data:', error);
    }
  };

  useEffect(() => {
    fetchMessageData();
  }, [receiverId]);

  const handleSendMessage = async () => {
    const user = auth.currentUser;
    const currentUserId = user ? user.uid : null;

    if (inputMessage.trim() !== '') {
      try {
        const sortedUserIds = [currentUserId, receiverId].sort();
        const conversationQuery = query(
          collection(db, 'messages'),
          where('userIds', '==', sortedUserIds)
        );

        const conversationSnapshot = await getDocs(conversationQuery);

        if (conversationSnapshot.size > 0) {
          const conversationDoc = conversationSnapshot.docs[0].ref;
          await addDoc(collection(conversationDoc, 'chats'), {
            senderID: currentUserId,
            receiverID: receiverId,
            content: inputMessage,
            timestamp: new Date().toISOString(),
            status: 'sent',
            seen: false
          });
        } else {
          const currentUserDoc = await getDoc(doc(db, 'users', currentUserId));
          const receiverUserDoc = await getDoc(doc(db, 'users', receiverId));

          const currentUserData = currentUserDoc.data();
          const receiverUserData = receiverUserDoc.data();
          const currentUserName = currentUserData.displayName;
          const receiverUserName = receiverUserData.displayName;

          // Fetching user data and constructing an array
          const usersData = [
            { id: currentUserId, name: currentUserName, ...currentUserData },
            { id: receiverId, name: receiverUserName, ...receiverUserData }
          ];

          const messageRef = await addDoc(collection(db, 'messages'), {
            participantNames: [currentUserName, receiverUserName],
            userIds: sortedUserIds,
            users: usersData,
            createdAt: serverTimestamp()
          });

          await addDoc(collection(messageRef, 'chats'), {
            senderID: currentUserId,
            receiverID: receiverId,
            content: inputMessage,
            timestamp: new Date().toISOString(),
            status: 'sent',
            seen: false
          });
        }

        setInputMessage('');
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  // Function to get sender or receiver image URL
  const getMessageUserImage = (userType) => {
    // Assuming messageData contains the users array
    const users = messageData.users;
    const currentUser = auth.currentUser;

    // Find the sender and receiver users
    const sender = users.find(user => user.id === messages[0].senderID);
    const receiver = users.find(user => user.id !== messages[0].senderID);

    // Return the image URL based on user type
    if (userType === 'sender') {
      return sender ? sender.img : currentUser.photoURL; // Use current user's image if sender not found
    } else if (userType === 'receiver') {
      return receiver ? receiver.img : ''; // Return empty string if receiver not found
    }
  };

  return (
    <div className='h-full flex flex-col justify-between'>
      <div className='p-2 overflow-y-auto overflow-hidden scrollbar-thin flex flex-col-reverse h-full' ref={messageContainerRef}>
        {messages.slice().reverse().map((message, index) => (
          <div key={index} className='p-1 w-full'>
            {message.senderID === auth.currentUser.uid ? (
              <SenderMessage key={index} message={message} getMessageUserImage={getMessageUserImage} />
            ) : (
              <ReceiverMessage key={index} message={message} img={img} getMessageUserImage={getMessageUserImage} />
            )}
          </div>
        ))}
      </div>
      <div className='flex w-full gap-2 justify-around items-center p-2 bg-zinc-50'>
        <textarea
          id="prompt-textarea"
          placeholder="Message"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          rows="1"
          className='w-full h-[45px] min-h-[45px] max-h-[100px] rounded-xl scrollbar-thin border-0 resize-none shadow-md'
        />
        <button
          className='flex h-[40px] w-[40px] rounded-full bg-slate-200 justify-center items-center text-center text-[12px] border shadow-xl'
          onClick={handleSendMessage}
          disabled={inputMessage.trim() === ''}
        >
          Send
        </button>
      </div>
    </div>
  );
};

const SenderMessage = ({ message, getMessageUserImage }) => {
  const userData = useUserData();

  return (
    <div className='flex flex-shrink-0 mt-1 mb-1 gap-2 flex-row-reverse mx-0'>
      <div className='flex flex-col items-center'>
        <img src={userData?.img || getMessageUserImage('receiver')} className='h-[30px] w-[30px] shrink-0 rounded-full bg-zinc-100' alt="user-avatar" />
        <div className='text-[10px] pt-1'>{new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}</div>
      </div>
      <div className='bg-indigo-200 rounded-2xl px-4 py-2 max-w-[70%]'>
        {message.content}
      </div>
    </div>
  );
};

const ReceiverMessage = ({ message, img }) => {
  return (
    <div className='flex flex-shrink-0 mt-1 mb-1 gap-2 mx-0'>
      <div className='flex flex-col items-center'>
        <img src={img} className='h-[30px] w-[30px] shrink-0 rounded-full bg-zinc-100' alt="user-avatar" />
        <div className='text-[10px] pt-1'>{new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}</div>
      </div>
      <div className='bg-slate-200 rounded-2xl px-4 py-2 max-w-[70%]'>
        {message.content}
      </div>
    </div>
  );
};

export default Messages;
