import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DarkModeContext } from "../context/darkModeContext";

const RecycleBin = () => {
  const userId = auth.currentUser.uid;
  const { darkMode } = useContext(DarkModeContext);
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const fetchData = async () => {
    try {
      const recycleBinCollection = collection(db, "recycleBin");
      const snapshot = await getDocs(recycleBinCollection);
      const recycleBinData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(recycleBinData);
    } catch (error) {
      console.error("Error fetching recycle bin data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAction = async (action) => {
    if (selectedRows.length === 0) {
      toast.error("Please select at least one item.");
      return;
    }

    const actionText = action === 'retrieve' ? 'retrieve' : 'delete';
    const confirmMessage = `Are you sure you want to ${actionText} the selected item(s)?`;

    // Show confirmation alert
    confirmAlert({
      title: `Confirm ${actionText}`,
      message: confirmMessage,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              if (action === 'retrieve') {
                await Promise.all(selectedRows.map(row => handleRetrieve(row.id, row.origin)));
              } else if (action === 'delete') {
                await Promise.all(selectedRows.map(row => handleDelete(row.id)));
              }
              setSelectedRows([]);
              fetchData(); // Fetch data after successful action
            } catch (err) {
              console.error(`Error ${actionText}ing item: `, err);
              toast.error(`Failed to ${actionText} item.`);
            }
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const handleRetrieve = async (id, origin) => {
    try {
      // Retrieve the item from the Recycle Bin
      const recycleBinDocRef = doc(db, "recycleBin", id);
      const recycleBinDocSnapshot = await getDoc(recycleBinDocRef);

      if (recycleBinDocSnapshot.exists()) {
        const retrievedData = recycleBinDocSnapshot.data();
        const originalCollectionRef = collection(db, origin);

        // Add the item back to its original collection
        await setDoc(doc(originalCollectionRef, id), retrievedData);

        // Delete the item from the Recycle Bin
        await deleteDoc(recycleBinDocRef);

        // Update UI
        setData(data.filter((item) => item.id !== id));

        // Show success message
        toast.success("Item retrieved successfully!");
      } else {
        toast.error("Item not found in Recycle Bin.");
      }
    } catch (err) {
      console.error("Error retrieving item: ", err);
      toast.error("Failed to retrieve item.");
    }
  };

  const handleDelete = async (id) => {
    try {
      // Delete the item from the Recycle Bin
      const recycleBinDocRef = doc(db, "recycleBin", id);
      await deleteDoc(recycleBinDocRef);

      // Update UI
      setData(data.filter((item) => item.id !== id));

      // Show success message
      toast.success("Item deleted successfully!");
    } catch (err) {
      console.error("Error deleting item: ", err);
      toast.error("Failed to delete item.");
    }
  };

  const columns = [
    { 
      field: 'id', 
      headerName: 'ID', 
      width: 100,
      headerClassName: `${darkMode ? "text-white" : "light-header"}`,
      renderCell: (params) => {
      return (
        <div className={`flex flex-shrink-0 justify-center items-center gap-1 ${darkMode ? "text-white" : "light-mode"}`}>
          {params.row.id}
        </div>
      );
    },
    },
    { 
      field: 'origin', 
      headerName: 'Origin', 
      width: 150,
      headerClassName: `${darkMode ? "text-white" : "light-header"}`,
      renderCell: (params) => {
      return (
        <div className={`flex flex-shrink-0 justify-center items-center gap-1 ${darkMode ? "text-white" : "light-mode"}`}>
          {params.row.origin}
        </div>
      );
    },
    },
    { 
      field: 'MovieTitle', 
      headerName: 'Name', 
      width: 150, valueGetter: (params) => params.row.MovieTitle || params.row.displayName,
      headerClassName: `${darkMode ? "text-white" : "light-header"}`,
      renderCell: (params) => {
      return (
        <div className={`flex flex-shrink-0 justify-center items-center gap-1 ${darkMode ? "text-white" : "light-mode"}`}>
          {params.row.MovieTitle || params.row.displayName}
        </div>
      );
    },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      headerClassName: `${darkMode ? "text-white" : "light-header"}`,
      renderCell: (params) => {
        return (
          <div className="flex items-center gap-4">
            <div
              className="text-green-500 border border-dotted border-green-500 hover:bg-black rounded px-2 py-1 cursor-pointer"
              onClick={() => handleAction('retrieve')}
            >
              Retrieve
            </div>
            <div
              className="text-red-500 border border-dotted border-red-500 hover:bg-black rounded px-2 py-1 cursor-pointer"
              onClick={() => handleAction('delete')}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="h-full p-5">
      <div className="flex items-center justify-between mb-4">
        <span className="text-gray-600 text-lg font-semibold">Recycle Bin</span>
        <div className="flex gap-2">
          <Link to="/" className="link text-blue-600 border border-blue-600 rounded px-3 py-1 cursor-pointer">
            Back to Home
          </Link>
          <div
            className="text-green-500 border border-dotted border-green-500 hover:bg-black rounded px-2 py-1 cursor-pointer"
            onClick={() => handleAction('retrieve')}
          >
            Retrieve
          </div>
          <div
            className="text-red-500 border border-dotted border-red-500 hover:bg-black rounded px-2 py-1 cursor-pointer"
            onClick={() => handleAction('delete')}
          >
            Delete
          </div>
        </div>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        checkboxSelection
        selectionModel={selectedRows.map(row => row.id)}
        onSelectionModelChange={(newSelection) => {
          setSelectedRows(data.filter(row => newSelection.includes(row.id)));
        }}
      />
    </div>
  );
};

export default RecycleBin;