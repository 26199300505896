import React, { useState, useEffect } from "react";
import { getToken } from "firebase/messaging";
import { messaging } from './firebase';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
  Navigate
} from "react-router-dom";
import { useContext } from "react";
import { ToastContainer } from 'react-toastify';
import { productInputs, userInputs } from "./formSource";
import Intro from "./Intro";
import Register from "./screens/Register";
import RegisterProfile from "./screens/RegisterProfile";
import Login from "./screens/Login";
import Dashboard from "./screens/Dashboard";

import Navbar from "./components/Navbar";
import SideBar from "./components/SideBar";
import Footer from "./components/Footer";

import Users from "./screens/Users";
import Admin from "./pages/Admin";
import Support from "./pages/Support";
import Producer from "./pages/Producer";
import Distributor from './pages/Distributor';
import User from "./pages/User";
import Distributors from "./pages/Distributors";
import Producers from "./pages/Producers";
import Trendings from "./pages/Trendings";

import Profile from "./pages/Profile";
import RecycleBin from "./pages/RecycleBin";
import ComingSoon from "./pages/ComingSoon";

import Library from "./screens/Library";
import Movies from "./screens/Movies";
import Film from "./pages/Film";
import Movie from "./pages/Movie";
import EditFilm from "./pages/EditFilm";
import EditMovie from "./pages/EditMovie";

import Posts from "./screens/Posts";
import New from "./screens/New";

import Create from "./screens/Create";
import CreatePost from "./pages/CreatePost";
import CreateRelease from "./pages/CreateRelease";
import CreateUser from "./pages/CreateUser";
import Upload from "./pages/Upload";

import ErrorBoundary from "./ErrorBoundary";
import AuthLayout from "./AuthLayout";

import { DarkModeContext } from './context/darkModeContext';
import { AuthContext } from './context/AuthContext';
import { updateCurrentUser } from "firebase/auth";

import Messages from "./screens/Messages";
import Feedbacks from "./pages/Feedbacks";
import Notifications from "./pages/Notifications";
import EditProfile from "./pages/EditProfile";

const PrivateRoute = ({ element, ...props }) => {
  const { currentUser } = useContext(AuthContext);
  return currentUser ? element : <Navigate to="/login" />;
};

const PublicRoute = ({ element, ...props }) => {
  const { currentUser } = useContext(AuthContext);
  return currentUser ? <Navigate to="/" /> : element;
};

const Layout = () => {
  const { darkMode } = useContext(DarkModeContext);
  
  return (
    <>
      <div className={`h-screen w-screen bg-[#F9FAFB] ${darkMode ? "bg-[#1b082b] text-white" : "light-mode"}`}>
        <div className={`flex w-full h-full fixed ${darkMode ? "bg-[#1b082b] text-white" : "light-mode"}`}>
          <SideBar />
          <div className={`mx-5 mr-12 h-full w-[88%] ${darkMode ? "bg-[#1b082b] text-white" : "light-mode"}`}>
            <Navbar />
            {/* Main */}
            <div className={`flex flex-col h-full justify-between flex-1 overflow-y-auto ${darkMode ? "bg-[#1b082b] text-white" : "light-mode"}`}>
              <div className={`mx-5 mr-5 h-full overflow-y-auto scrollbar- thin max-sm: scrollbar-hide lg:scrollbar -wider ${darkMode ? "bg-[#1b082b] text-white" : "light-mode"}`}>
                <Outlet />
              </div>
              <div className={`w-full mt-auto mb-[90px] rounded-xl ${darkMode ? "bg-[#40394d5d] text-white" : "light-mode bg-gray-100"}`}>
                <Footer />
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <PrivateRoute element={<Dashboard />} />,
      },
      {
        path: "/trendings",
        element: <PrivateRoute element={<Trendings />} />,
      },
      {
        path: "/distributors",
        element: <PrivateRoute element={<Distributors />} />,
      },
      {
        path: "/producers",
        element: <PrivateRoute element={<Producers />} />,
      },
      {
        path: "/edit-profile",
        element: <PrivateRoute element={<EditProfile />} />,
      },
      {
        path: "/profile",
        element: <PrivateRoute element={<Profile />} />,
      },
      {
        path: "/recyclebin",
        element: <PrivateRoute element={<RecycleBin />} />,
      },
      {
        path: "/messages",
        element: <PrivateRoute element={<Messages />} />,
      },
      {
        path: "/notifications",
        element: <PrivateRoute element={<Notifications />} />,
      },
      // {
      //   path: "/feedbacks",
      //   element: <PrivateRoute element={<Feedbacks />} />,
      // },
      {
        path: "/staticts",
        element: <PrivateRoute element={<ComingSoon />} />,
      },
      {
        path: "/revenue",
        element: <PrivateRoute element={<ComingSoon />} />,
      },
      {
        path: "/revenue/transactions",
        element: <PrivateRoute element={<ComingSoon />} />,
      },
      // {
      //   path: "/subscription",
      //   element: <PrivateRoute element={<ComingSoon />} />,
      // },
      // {
      //   path: "/subscription/edit",
      //   element: <PrivateRoute element={<ComingSoon />} />,
      // },
      // {
      //   path: "/users",
      //   element: <PrivateRoute element={<Users />} />,
      // },
      // {
      //   path: "/users/admin/:id",
      //   element: <PrivateRoute element={<Admin />} />,
      // },
      // {
      //   path: "/users/support/:id",
      //   element: <PrivateRoute element={<Support />} />,
      // },
      {
        path: "/producer/:id",
        element: <PrivateRoute element={<Producer />} />,
      },
      // {
      //   path: "/users/distributor/:id",
      //   element: <PrivateRoute element={<Distributor />} />,
      // },
      // {
      //   path: "/users/user/:id",
      //   element: <PrivateRoute element={<User />} />,
      // },
      {
        path: "/library",
        element: <PrivateRoute element={<Library />} />,
      },
      {
        path: "/movies",
        element: <PrivateRoute element={<Movies />} />,
      },
      // {
      //   path: "/movies/film/edit/:id",
      //   element: <PrivateRoute element={<EditFilm />} />,
      // },
      {
        path: "/movies/film/:id",
        element: <PrivateRoute element={<Film />} />,
      },
      // {
      //   path: "/movies/movie/edit/:id",
      //   element: <PrivateRoute element={<EditMovie />} />,
      // },
      {
        path: "/movies/movie/:id",
        element: <PrivateRoute element={<Movie />} />,
      },
      {
        path: "/posts",
        element: <PrivateRoute element={<Posts />} />,
      },
      {
        path: "/new-release",
        element: <PrivateRoute element={<New />} />,
      },
      // {
      //   path: "/create",
      //   element: <PrivateRoute element={<Create />} />,
      // },
      // {
      //   path: "/create/post",
      //   element: <PrivateRoute element={<CreatePost />} />,
      // },
      // {
      //   path: "/create/release",
      //   element: <PrivateRoute element={<CreateRelease />} />,
      // },
      // {
      //   path: "/create/upload",
      //   element: <PrivateRoute element={<Upload />} />,
      // },
      // {
      //   path: "/create/user",
      //   element: <PrivateRoute element={<CreateUser inputs={userInputs} title="Add New User" />} />,
      // },
      {
        path: "/register-profile/:profileId",
        element: <PublicRoute element={<RegisterProfile inputs={userInputs} title="Register Profile" />} />,
      },
    ],
  },
  { 
    element: <AuthLayout />,
      children: [
        {
          path: "/login",
          element: <PublicRoute element={<Login />} />,
          // loader: redirectIfUser,
        },
        {
          path: "/register",
          element: <PublicRoute element={<Register inputs={userInputs} title="Register Profile" />} />,
        },
        {
          path: "logout",
          // action: logoutUser,
        },
      ]
  }
]);

function App() {
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIntro(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="">
      <div className="">
        {showIntro ? <Intro /> : <RouterProvider router={router} />}
      </div>
    </div>
  );
}

export default App;