import React, { useContext, useEffect, useState } from "react";
import { DarkModeContext } from '../context/darkModeContext';
import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";
import { db } from "../firebase";
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';

const ThisWeekPostTable = () => {
  const { darkMode } = useContext(DarkModeContext);
  const [newPosts, setNewPosts] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [totalNewPosts, setTotalNewPosts] = useState(0);

  useEffect(() => {
    const fetchNewPosts = async () => {
      try {
        // Calculate the start and end dates for the current week
        const startOfWeek = new Date(currentDate);
        startOfWeek.setHours(0, 0, 0, 0); // Set to the beginning of the day
        const endOfWeek = new Date(currentDate);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        endOfWeek.setHours(23, 59, 59, 999); // Set to the end of the day

        // Convert start and end of the week to Firestore Timestamps
        const startOfWeekTimestamp = Timestamp.fromDate(startOfWeek);
        const endOfWeekTimestamp = Timestamp.fromDate(endOfWeek);

        // Fetch posts made during the current week (starting from Monday)
        const postsCollection = collection(db, 'posts');
        const q = query(
          postsCollection,
          where('timestamp', '>=', startOfWeekTimestamp),
          where('timestamp', '<=', endOfWeekTimestamp)
        );
        const querySnapshot = await getDocs(q);
        const fetchedPosts = [];
        querySnapshot.forEach((doc) => {
          fetchedPosts.push(doc.data());
        });
        setNewPosts(fetchedPosts);

        // Update the total number of posts
        setTotalNewPosts(fetchedPosts?.length);
      } catch (error) {
        console.error('Error fetching new posts:', error);
      }
    };

    fetchNewPosts();
  }, [currentDate]);

  const getTimeDifference = (postDate) => {
    const currentDate = new Date();
    const diff = (currentDate.getTime() - postDate.getTime()) / 1000;

    if (diff < 60) {
      return 'just now';
    }

    const minutes = Math.floor(diff / 60);

    if (minutes < 60) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    }

    const hours = Math.floor(minutes / 60);

    if (hours < 24) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    }

    const days = Math.floor(hours / 24);
    return `${days} day${days > 1 ? 's' : ''} ago`;
  };

  return (
    <table className='w-[100%] mx-auto shadow-md mb-4'>
      <thead>
        <tr>
          <td className={`p-2 ${darkMode ? "bg-[#302c3878] border text-white" : "light-mode bg-white py-5 "}`} style={{ fontWeight: 'bold', paddingLeft: 20 }}>
            Title
          </td>
          <td className={`p-2 ${darkMode ? "bg-[#302c3878] border text-white" : "light-mode bg-white py-5 "}`} style={{ fontWeight: 'bold', paddingLeft: 20 }}>
            Episodes
          </td>
          <td className={`p-2 ${darkMode ? "bg-[#302c3878] border text-white" : "light-mode bg-white py-5 "}`} style={{ fontWeight: 'bold', paddingLeft: 20 }}>
            Producer
          </td>
          <td className={`p-2 ${darkMode ? "bg-[#302c3878] border text-white" : "light-mode bg-white py-5 "}`} style={{ fontWeight: 'bold', paddingLeft: 20 }}
          >Posted
          </td>
          <td className={`p-2 ${darkMode ? "bg-[#302c3878] border text-white" : "light-mode bg-white py-5 "}`} style={{ fontWeight: 'bold', paddingLeft: 20 }}>
            Status
          </td>
        </tr>
      </thead>
      <tbody>
        {newPosts.map((post, index) => (
          <tr key={index} className={index % 2 === 0 ? `${darkMode ? "border text-white" : "light-mode bg-gray-100 "}` : `${darkMode ? "bg-[#17222978] text-white" : "light-mode bg-gray-200"}`}>
            <td className={`p-2 hover:text-blue-500 ${darkMode ? "text-white" : "light-mode"}`} style={{ paddingLeft: 20 }}>
              <Link to={`/movies/${post.Type === 'Single Film' ? 'film' : 'movie'}/${post.season}`}>{post.MovieTitle}</Link>
            </td>
            <td className={`p-2 ${darkMode ? "text-white" : "light-mode"}`} style={{ paddingLeft: 20 }}>
              {post.episodes?.length} Episodes
            </td>
            <td className={`p-2 ${darkMode ? "text-white" : "light-mode"}`} style={{ paddingLeft: 20 }}>
              {post.ProducerName}
            </td>
            <td className={`p-2 ${darkMode ? "text-white" : "light-mode"}`} style={{ paddingLeft: 20 }}>
              {getTimeDifference(post.timestamp.toDate())}
            </td>
            <td className={`p-2 status px-2 py-1 ${post.Finish === 'Finish' ? `${darkMode ? "text-white" : "light-mode text-green-500 bg-green-100"}` : `${darkMode ? "text-white" : "light-mode text-yellow-500 bg-yellow-100"}`}`} style={{ paddingLeft: 20 }}>
              {post.Finish === true && <div className='text-blue-500'>Final</div> || "Continues"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ThisWeekPostTable;